import React from 'react';
import './Footer.css';
import logo from "../../../asset/logo.png";
import twit from '../../../asset/footer/twi.png'
import github from '../../../asset/footer/git.png'
import tele from '../../../asset/footer/tel.png'
import dis from '../../../asset/footer/dis.png'
import mm from '../../../asset/footer/m.PNG'
import { useTranslation } from "react-i18next";
const Footer = () => {
    const { t } = useTranslation();
    return (
        <div className='footer'>
            <img className='footer-logo' src={logo} alt="" />
            <div className='social-logo'>
            <a href='https://twitter.com/minmaxexchange' target="_blank" rel="noopener noreferrer"> <img src={twit} alt="" /> </a>
            <a href='https://minmaxdex.medium.com/' target="_blank" rel="noopener noreferrer"><img src={mm} alt="" /></a>
            <a href='https://github.com/MinMax-Dex' target="_blank" rel="noopener noreferrer"><img src={github} alt="" /></a>
            <a href='https://t.me/minmaxdex' target="_blank" rel="noopener noreferrer"><img src={tele} alt="" /></a>
            <a href='https://discord.com/invite/minmax' target="_blank" rel="noopener noreferrer"><img src={dis} alt="" /></a>
            </div>
           
                <div className='footer-link'>
                    <li>
                        <a href='https://app.minmax.exchange/#/terms-and-conditions' target="_blank" rel="noopener noreferrer">
                            {t("Footerlinks.1")}
                        </a>
                    </li>
                    <li>
                        <a href='https://app.minmax.exchange/#/referral-terms' target="_blank" rel="noopener noreferrer">
                            {t("Footerlinks.2")}
                        </a>
                    </li>
                    <li>
                        <a href='https://minmaxdex.gitbook.io/minmaxdex/' target="_blank" rel="noopener noreferrer">
                            {t("Footerlinks.3")}
                        </a>
                    </li>
                </div>
        </div>
    );
};

export default Footer;