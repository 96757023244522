
import { Grid } from '@mui/material';
import React from 'react';
import {useMemo, useCallback} from 'react';
import { RiLoader5Fill } from 'react-icons/ri'
import { useTranslation } from "react-i18next";
import { BigNumber, BigNumberish, ethers } from "ethers";
import useSWR from "swr";


import {
  useVolumeData,
  useFeesData,
  useGlpData,
  useGlpPerformanceData,
  useTradersData,
  useSwapSources,
  useFundingRateData,
  useUsersData,
  useLastSubgraphBlock,
  useLastBlock
} from '../../dataProvider'

import {
  yaxisFormatterNumber,
  yaxisFormatterPercent,
  yaxisFormatter,
  tooltipLabelFormatter,
  tooltipLabelFormatterUnits,
  tooltipFormatter,
  tooltipFormatterNumber,
  tooltipFormatterPercent,
  formatNumber,
  CHART_HEIGHT,
  YAXIS_WIDTH,
  COLORS,
  GREEN,
  RED,
  convertToPercents
} from '../../helpers'

import Header from '../Shared/Header';
import trading from '../../asset/trading.png'
import balance from '../../asset/balance.png'
import user from '../../asset/user.png'
import './Home.css'
import { Container } from '@mui/system';
import reduceIco from '../../asset/reduce.png'
import saveIco from '../../asset/save.png'
import simpleIco from '../../asset/simple.png'
import mmx from '../../asset/mmx.png'
import lmx from '../../asset/lmx.png'
import Footer from '../Shared/Footer/Footer';
import { useWeb3React } from "@web3-react/core";

import Vault from "../../abis/Vault.json";
import ReaderV2 from "../../abis/ReaderV2.json";
import RewardReader from "../../abis/RewardReader.json";
import Token from "../../abis/Token.json";
import GlpManager from "../../abis/GlpManager.json";
import UniswapV2 from "../../abis/UniswapV2.json";

import { getContract } from "../../config/contracts";
import {AVALANCHE} from "../../config/contracts";
import { contractFetcher } from '../../lib/contracts/contractFetcher.ts';

export const PLACEHOLDER_ACCOUNT = ethers.Wallet.createRandom().address;
const BASIS_POINTS_DIVISOR = 10000;
const SECONDS_PER_YEAR = 31536000;
const GLP_DECIMALS = 18;

export function bigNumberify(n: BigNumberish) {
  try {
    return BigNumber.from(n);
  } catch (e) {
    console.error("bigNumberify error", e);
    return undefined;
  }
}

export const formatKeyAmount = (
  map: any,
  key: string,
  tokenDecimals: number,
  displayDecimals: number,
  useCommas?: boolean
) => {
  if (!map || !map[key]) {
    return "...";
  }

  return formatAmount(map[key], tokenDecimals, displayDecimals, useCommas);
};

export const formatAmount = (
  amount: BigNumberish | undefined,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean,
  defaultValue?: string
) => {
  if (!defaultValue) {
    defaultValue = "...";
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals);
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals);
  }
  if (useCommas) {
    return numberWithCommas(amountStr);
  }
  return amountStr;
};

export const padDecimals = (amount: BigNumberish, minDecimals: number) => {
  let amountStr = amount.toString();
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(amountStr.length + (minDecimals - decimals), "0");
    }
  } else {
    amountStr = amountStr + ".0000";
  }
  return amountStr;
};

export function numberWithCommas(x: BigNumberish) {
  // if (!x) {
  //   return "...";
  // }
  if (typeof x == 'undefined') {
    return "...";
  }

  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}


export const limitDecimals = (amount: BigNumberish, maxDecimals?: number) => {
  let amountStr = amount.toString();
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split(".")[0];
  }
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    let decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals));
    }
  }
  return amountStr;
};


function useGmxPriceFromAvalanche() {
  const poolAddress = getContract(AVALANCHE, "TraderJoeGmxAvaxPool");

  const { data, mutate: updateReserves } = useSWR(["TraderJoeGmxAvaxReserves", AVALANCHE, poolAddress, "getReserves"], {
    fetcher: contractFetcher(undefined, UniswapV2),
  });
  const { _reserve0: avaxReserve, _reserve1: gmxReserve } = data || {};

  const vaultAddress = getContract(AVALANCHE, "Vault");
  // const avaxAddress = getTokenBySymbol(AVALANCHE, "WAVAX").address;
  const avaxAddress = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
  const { data: avaxPrice, mutate: updateAvaxPrice } = useSWR(
    [`StakeV2:avaxPrice`, AVALANCHE, vaultAddress, "getMinPrice", avaxAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
    }
  );

  const PRECISION = bigNumberify(10).pow(18);
  let gmxPrice;
  if (avaxReserve && gmxReserve && avaxPrice) {
    gmxPrice = avaxReserve.mul(PRECISION).div(gmxReserve).mul(avaxPrice).div(PRECISION);
  }

  const mutate = useCallback(() => {
    updateReserves(undefined, true);
    updateAvaxPrice(undefined, true);
  }, [updateReserves, updateAvaxPrice]);

  return { data: gmxPrice, mutate };
}

const Home = () => {
    const { t} = useTranslation();
    const [totalVolumeData, totalVolumeLoading] = useVolumeData({ chainName: 'avalanche' })
    const [totalUsersData, totalUsersLoading] = useUsersData({ chainName: 'avalanche' })

    const [totalVolume, totalVolumeDelta] = useMemo(() => {
      if (!totalVolumeData) {
        return []
      }
      const total = totalVolumeData[totalVolumeData.length - 1]?.cumulative
      const delta = total - totalVolumeData[totalVolumeData.length - 2]?.cumulative
      return [total, delta]
    }, [totalVolumeData])

    const [totalUsers, totalUsersDelta] = useMemo(() => {
      if (!totalUsersData) {
        return [null, null]
      }
      const total = totalUsersData[totalUsersData.length - 1]?.uniqueCountCumulative
      const prevTotal = totalUsersData[totalUsersData.length - 2]?.uniqueCountCumulative
      const delta = total && prevTotal ? total - prevTotal : null
      return [
        total,
        delta
      ]
    }, [totalUsersData])

  const [totalTradersData, totalTradersLoading] = useTradersData({ chainName: 'avalanche' })

  const [openInterest, openInterestDelta] = useMemo(() => {
    if (!totalTradersData) {
      return []
    }
    const total = totalTradersData.data[totalTradersData.data.length - 1]?.openInterest
    const delta = total - totalTradersData.data[totalTradersData.data.length - 2]?.openInterest
    return [total, delta]
  }, [totalTradersData])

  // let { active } = useWeb3React();
  let active = true;
  const rewardReaderAddress = getContract(AVALANCHE, "RewardReader");
  const readerAddress = getContract(AVALANCHE, "Reader");
  const vaultAddress = getContract(AVALANCHE, "Vault");
  const nativeTokenAddress = getContract(AVALANCHE, "NATIVE_TOKEN");
  const gmxAddress = getContract(AVALANCHE, "GMX");
  const esGmxAddress = getContract(AVALANCHE, "ES_GMX");
  const bnGmxAddress = getContract(AVALANCHE, "BN_GMX");
  const glpAddress = getContract(AVALANCHE, "GLP");

  const stakedGmxTrackerAddress = getContract(AVALANCHE, "StakedGmxTracker");
  const bonusGmxTrackerAddress = getContract(AVALANCHE, "BonusGmxTracker");
  const feeGmxTrackerAddress = getContract(AVALANCHE, "FeeGmxTracker");

  const stakedGlpTrackerAddress = getContract(AVALANCHE, "StakedGlpTracker");
  const feeGlpTrackerAddress = getContract(AVALANCHE, "FeeGlpTracker");

  const glpManagerAddress = getContract(AVALANCHE, "GlpManager");

  const gmxVesterAddress = getContract(AVALANCHE, "GmxVester");
  const glpVesterAddress = getContract(AVALANCHE, "GlpVester");

  const walletTokens = [gmxAddress, esGmxAddress, glpAddress, stakedGmxTrackerAddress];
  const depositTokens = [
    gmxAddress,
    esGmxAddress,
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    bnGmxAddress,
    glpAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedGmxTrackerAddress,
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    feeGmxTrackerAddress,
    feeGmxTrackerAddress,
    feeGlpTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedGmxTrackerAddress,
    bonusGmxTrackerAddress,
    feeGmxTrackerAddress,
    stakedGlpTrackerAddress,
    feeGlpTrackerAddress,
  ];
  
  const vesterAddresses = [gmxVesterAddress, glpVesterAddress];

  const { data: walletBalances } = useSWR(
    [`StakeV2:walletBalances:${active}`, AVALANCHE, readerAddress, "getTokenBalancesWithSupplies", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [`StakeV2:depositBalances:${active}`, AVALANCHE, rewardReaderAddress, "getDepositBalances", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`StakeV2:stakingInfo:${active}`, AVALANCHE, rewardReaderAddress, "getStakingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  const { data: stakedGmxSupply } = useSWR(
    [`StakeV2:stakedGmxSupply:${active}`, AVALANCHE, gmxAddress, "balanceOf", stakedGmxTrackerAddress],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  const { data: aums } = useSWR([`StakeV2:getAums:${active}`, AVALANCHE, glpManagerAddress, "getAums"], {
    fetcher: contractFetcher(undefined, GlpManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`StakeV2:nativeTokenPrice:${active}`, AVALANCHE, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: contractFetcher(undefined, Vault),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`StakeV2:vestingInfo:${active}`, AVALANCHE, readerAddress, "getVestingInfo", PLACEHOLDER_ACCOUNT],
    {
      fetcher: contractFetcher(undefined, ReaderV2, [vesterAddresses]),
    }
  );
  function getBalanceAndSupplyData(balances) {
    if (!balances || balances.length === 0) {
      return {};
    }
  
    const keys = ["gmx", "esGmx", "glp", "stakedGmxTracker"];
    const balanceData = {};
    const supplyData = {};
    const propsLength = 2;
  
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      balanceData[key] = balances[i * propsLength];
      supplyData[key] = balances[i * propsLength + 1];
    }
  
    return { balanceData, supplyData };
  }

  function getDepositBalanceData(depositBalances) {
    if (!depositBalances || depositBalances.length === 0) {
      return;
    }
  
    const keys = [
      "gmxInStakedGmx",
      "esGmxInStakedGmx",
      "stakedGmxInBonusGmx",
      "bonusGmxInFeeGmx",
      "bnGmxInFeeGmx",
      "glpInStakedGlp",
    ];
    const data = {};
  
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      data[key] = depositBalances[i];
    }
  
    return data;
  }

  function getStakingData(stakingInfo) {
    if (!stakingInfo || stakingInfo.length === 0) {
      return;
    }
  
    const keys = ["stakedGmxTracker", "bonusGmxTracker", "feeGmxTracker", "stakedGlpTracker", "feeGlpTracker"];
    const data = {};
    const propsLength = 5;
  
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      data[key] = {
        claimable: stakingInfo[i * propsLength],
        tokensPerInterval: stakingInfo[i * propsLength + 1],
        averageStakedAmounts: stakingInfo[i * propsLength + 2],
        cumulativeRewards: stakingInfo[i * propsLength + 3],
        totalSupply: stakingInfo[i * propsLength + 4],
      };
    }
  
    return data;
  }

  const { data: gmxSupply } = useSWR(
    [`APRLabel:gmxSupply:${active}`, AVALANCHE, gmxAddress, "totalSupply"],
    {
      fetcher: contractFetcher(undefined, Token),
    }
  );

  function getVestingData(vestingInfo) {
    if (!vestingInfo || vestingInfo.length === 0) {
      return;
    }
  
    const keys = ["gmxVester", "glpVester"];
    const data = {};
    const propsLength = 7;
  
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      data[key] = {
        pairAmount: vestingInfo[i * propsLength],
        vestedAmount: vestingInfo[i * propsLength + 1],
        escrowedBalance: vestingInfo[i * propsLength + 2],
        claimedAmounts: vestingInfo[i * propsLength + 3],
        claimable: vestingInfo[i * propsLength + 4],
        maxVestableAmount: vestingInfo[i * propsLength + 5],
        averageStakedAmount: vestingInfo[i * propsLength + 6],
      };
  
      data[key + "PairAmount"] = data[key].pairAmount;
      data[key + "VestedAmount"] = data[key].vestedAmount;
      data[key + "EscrowedBalance"] = data[key].escrowedBalance;
      data[key + "ClaimSum"] = data[key].claimedAmounts.add(data[key].claimable);
      data[key + "Claimable"] = data[key].claimable;
      data[key + "MaxVestableAmount"] = data[key].maxVestableAmount;
      data[key + "AverageStakedAmount"] = data[key].averageStakedAmount;
    }
  
    return data;
  }
  
  function expandDecimals(n: BigNumberish, decimals: number): BigNumber {
    // @ts-ignore
    return bigNumberify(n).mul(bigNumberify(10).pow(decimals));
  }
  
  function getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmxSupply,
    gmxPrice,
    gmxSupply
  ) {
    if (
      !balanceData ||
      !supplyData ||
      !depositBalanceData ||
      !stakingData ||
      !vestingData ||
      !aum ||
      !nativeTokenPrice ||
      !stakedGmxSupply ||
      !gmxPrice ||
      !gmxSupply
    ) {
      return {};
    }
  
    const data = {};
  
    data.gmxBalance = balanceData.gmx;
    data.gmxBalanceUsd = balanceData.gmx.mul(gmxPrice).div(expandDecimals(1, 18));
  
    data.gmxSupply = bigNumberify(gmxSupply);
  
    data.gmxSupplyUsd = data.gmxSupply.mul(gmxPrice).div(expandDecimals(1, 18));
    data.stakedGmxSupply = stakedGmxSupply;
    data.stakedGmxSupplyUsd = stakedGmxSupply.mul(gmxPrice).div(expandDecimals(1, 18));
    data.gmxInStakedGmx = depositBalanceData.gmxInStakedGmx;
    data.gmxInStakedGmxUsd = depositBalanceData.gmxInStakedGmx.mul(gmxPrice).div(expandDecimals(1, 18));
  
    data.esGmxBalance = balanceData.esGmx;
    data.esGmxBalanceUsd = balanceData.esGmx.mul(gmxPrice).div(expandDecimals(1, 18));
  
    data.stakedGmxTrackerSupply = supplyData.stakedGmxTracker;
    data.stakedGmxTrackerSupplyUsd = supplyData.stakedGmxTracker.mul(gmxPrice).div(expandDecimals(1, 18));
    data.stakedEsGmxSupply = data.stakedGmxTrackerSupply.sub(data.stakedGmxSupply);
    data.stakedEsGmxSupplyUsd = data.stakedEsGmxSupply.mul(gmxPrice).div(expandDecimals(1, 18));
  
    data.esGmxInStakedGmx = depositBalanceData.esGmxInStakedGmx;
    data.esGmxInStakedGmxUsd = depositBalanceData.esGmxInStakedGmx.mul(gmxPrice).div(expandDecimals(1, 18));
  
    data.bnGmxInFeeGmx = depositBalanceData.bnGmxInFeeGmx;
    data.bonusGmxInFeeGmx = depositBalanceData.bonusGmxInFeeGmx;
    data.feeGmxSupply = stakingData.feeGmxTracker.totalSupply;
    data.feeGmxSupplyUsd = data.feeGmxSupply.mul(gmxPrice).div(expandDecimals(1, 18));
  
    data.stakedGmxTrackerRewards = stakingData.stakedGmxTracker.claimable;
    data.stakedGmxTrackerRewardsUsd = stakingData.stakedGmxTracker.claimable.mul(gmxPrice).div(expandDecimals(1, 18));
  
    data.bonusGmxTrackerRewards = stakingData.bonusGmxTracker.claimable;
  
    data.feeGmxTrackerRewards = stakingData.feeGmxTracker.claimable;
    data.feeGmxTrackerRewardsUsd = stakingData.feeGmxTracker.claimable.mul(nativeTokenPrice).div(expandDecimals(1, 18));
  
    data.boostBasisPoints = bigNumberify(0);
    if (data && data.bnGmxInFeeGmx && data.bonusGmxInFeeGmx && data.bonusGmxInFeeGmx.gt(0)) {
      data.boostBasisPoints = data.bnGmxInFeeGmx.mul(BASIS_POINTS_DIVISOR).div(data.bonusGmxInFeeGmx);
    }
  
    data.stakedGmxTrackerAnnualRewardsUsd = stakingData.stakedGmxTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(gmxPrice)
      .div(expandDecimals(1, 18));
    data.gmxAprForEsGmx =
      data.stakedGmxTrackerSupplyUsd && data.stakedGmxTrackerSupplyUsd.gt(0)
        ? data.stakedGmxTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.stakedGmxTrackerSupplyUsd)
        : bigNumberify(0);
    data.feeGmxTrackerAnnualRewardsUsd = stakingData.feeGmxTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(nativeTokenPrice)
      .div(expandDecimals(1, 18));
    data.gmxAprForNativeToken =
      data.feeGmxSupplyUsd && data.feeGmxSupplyUsd.gt(0)
        ? data.feeGmxTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.feeGmxSupplyUsd)
        : bigNumberify(0);
    data.gmxBoostAprForNativeToken = data.gmxAprForNativeToken.mul(data.boostBasisPoints).div(BASIS_POINTS_DIVISOR);
    data.gmxAprTotal = data.gmxAprForNativeToken.add(data.gmxAprForEsGmx);
    data.gmxAprTotalWithBoost = data.gmxAprForNativeToken.add(data.gmxBoostAprForNativeToken).add(data.gmxAprForEsGmx);
    data.gmxAprForNativeTokenWithBoost = data.gmxAprForNativeToken.add(data.gmxBoostAprForNativeToken);
  
    data.totalGmxRewardsUsd = data.stakedGmxTrackerRewardsUsd.add(data.feeGmxTrackerRewardsUsd);
  
    data.glpSupply = supplyData.glp;
    data.glpPrice =
      data.glpSupply && data.glpSupply.gt(0)
        ? aum.mul(expandDecimals(1, GLP_DECIMALS)).div(data.glpSupply)
        : bigNumberify(0);
  
    data.glpSupplyUsd = supplyData.glp.mul(data.glpPrice).div(expandDecimals(1, 18));
  
    data.glpBalance = depositBalanceData.glpInStakedGlp;
    data.glpBalanceUsd = depositBalanceData.glpInStakedGlp.mul(data.glpPrice).div(expandDecimals(1, GLP_DECIMALS));
  
    data.stakedGlpTrackerRewards = stakingData.stakedGlpTracker.claimable;
    data.stakedGlpTrackerRewardsUsd = stakingData.stakedGlpTracker.claimable.mul(gmxPrice).div(expandDecimals(1, 18));
  
    data.feeGlpTrackerRewards = stakingData.feeGlpTracker.claimable;
    data.feeGlpTrackerRewardsUsd = stakingData.feeGlpTracker.claimable.mul(nativeTokenPrice).div(expandDecimals(1, 18));
  
    data.stakedGlpTrackerAnnualRewardsUsd = stakingData.stakedGlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(gmxPrice)
      .div(expandDecimals(1, 18));
    data.glpAprForEsGmx =
      data.glpSupplyUsd && data.glpSupplyUsd.gt(0)
        ? data.stakedGlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.glpSupplyUsd)
        : bigNumberify(0);
    data.feeGlpTrackerAnnualRewardsUsd = stakingData.feeGlpTracker.tokensPerInterval
      .mul(SECONDS_PER_YEAR)
      .mul(nativeTokenPrice)
      .div(expandDecimals(1, 18));
      console.log("---shark glpAprTotal ");
    data.glpAprForNativeToken =
      data.glpSupplyUsd && data.glpSupplyUsd.gt(0)
        ? data.feeGlpTrackerAnnualRewardsUsd.mul(BASIS_POINTS_DIVISOR).div(data.glpSupplyUsd)
        : bigNumberify(0);
    data.glpAprTotal = data.glpAprForNativeToken.add(data.glpAprForEsGmx);
  
    data.totalGlpRewardsUsd = data.stakedGlpTrackerRewardsUsd.add(data.feeGlpTrackerRewardsUsd);
  
    data.totalEsGmxRewards = data.stakedGmxTrackerRewards.add(data.stakedGlpTrackerRewards);
    data.totalEsGmxRewardsUsd = data.stakedGmxTrackerRewardsUsd.add(data.stakedGlpTrackerRewardsUsd);
  
    data.gmxVesterRewards = vestingData.gmxVester.claimable;
    data.glpVesterRewards = vestingData.glpVester.claimable;
    data.totalVesterRewards = data.gmxVesterRewards.add(data.glpVesterRewards);
    data.totalVesterRewardsUsd = data.totalVesterRewards.mul(gmxPrice).div(expandDecimals(1, 18));
  
    data.totalNativeTokenRewards = data.feeGmxTrackerRewards.add(data.feeGlpTrackerRewards);
    data.totalNativeTokenRewardsUsd = data.feeGmxTrackerRewardsUsd.add(data.feeGlpTrackerRewardsUsd);
  
    data.totalRewardsUsd = data.totalEsGmxRewardsUsd.add(data.totalNativeTokenRewardsUsd).add(data.totalVesterRewardsUsd);
  
    return data;
  }
  const { data: gmxPrice, mutate: mutateFromAvalanche } = useGmxPriceFromAvalanche();

  // const { gmxPrice } = useGmxPriceFromAvalanche();
  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }
  
  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);
  
  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedGmxSupply,
    gmxPrice,
    gmxSupply
  );

    return (
      <div className="main-section">
        <Header />
        <div>
          <div className="home-header">
            <div className="line"></div>
            <div>
              <p className="heading">
                {t("Lines.1")} <br />
                {t("Lines.2")}
              </p>
              <p className="sub-heading">
                {t("Lines.3")} <br /> {t("Lines.4")}
              </p>
              {/* <button className="launch-btn">{t("Lines.5")}</button> */}
              <a href="https://app.minmax.exchange/#/trade" className="launch-btn" target="_blank" rel="noopener noreferrer">{t("Lines.5")}</a>
              <div className="grid-box">
                <Container style={{ maxWidth: "1400px" }}>
                  <Grid container rowSpacing={1}>
                    <Grid xs={12} md={4}>
                      <div className="hom-box">
                        <img src={trading} alt="" />
                        <div style={{ paddingLeft: "20px" }}>
                          <p className="grid-box-smt">{t("Lines.6")}</p>
                          {totalVolume ? <p className="grid-box-lgt">{formatNumber(totalVolume, { currency: true })}</p> : <p className="grid-box-lgt">$0</p>}
                          {totalVolumeLoading && <RiLoader5Fill size="3em" className="loader" />} 
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className="hom-box">
                        <img src={balance} alt="" />
                        <div style={{ paddingLeft: "20px" }}>
                          <p className="grid-box-smt">{t("Lines.7")}</p>
                          {openInterest ? <p className="grid-box-lgt">{formatNumber(openInterest, { currency: true })}</p> : <p className="grid-box-lgt">{t("Lines.10")}</p>}
                          {totalTradersLoading && <RiLoader5Fill size="3em" className="loader" />}
                        </div>
                      </div>
                    </Grid>
                    <Grid xs={12} md={4}>
                      <div className="hom-box">
                        <img src={user} alt="" id="user" />
                        <div style={{ paddingLeft: "20px" }}>
                          <p className="grid-box-smt">{t("Lines.8")}</p>
                          {totalUsers ? <p className="grid-box-lgt">{formatNumber(totalUsers, { compact: true })}</p> : <p className="grid-box-lgt">0</p>}
                          {totalUsersLoading && <RiLoader5Fill size="3em" className="loader" />}
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Container>
              </div>
            </div>
          </div>

          {/* ==========================second part */}
          <div className='blank-div'></div>
          <div className="home-second-part">
            <div>
              <Container style={{ maxWidth: "1400px" }}>
                <Grid container rowSpacing={1}>
                  <Grid xs={12} md={4}>
                    <div className="rss-part">
                      <div className="ico-text-inline">
                        <img src={reduceIco} alt="" />
                        <p className="rss-text">{t("Cardlines.1")}</p>
                      </div>
                      <p className="rss-para">{t("Cardlines.2")}</p>
                    </div>
                  </Grid>
                  <Grid xs={12} md={4}>
                    <div className="rss-part">
                      <div className="ico-text-inline">
                        <img src={saveIco} alt="" />
                        <p className="rss-text">{t("Cardlines.3")}</p>
                      </div>
                      <p className="rss-para">{t("Cardlines.4")}</p>
                    </div>
                  </Grid>
                  <Grid xs={12} md={4}>
                    <div className="rss-part">
                      <div className="ico-text-inline">
                        <img src={simpleIco} alt="" />
                        <p className="rss-text">{t("Cardlines.5")}</p>
                      </div>
                      <p className="rss-para">{t("Cardlines.6")}</p>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </div>
          </div>

          {/* ==================explore sectione// */}

          <div className="explore-section">
            <Container style={{ maxWidth: "1400px" }}>
              <div className="explor-head">
                <p className="explore-text">{t("Cardlines.7")}</p>
                <h1 className="explore-heading">{t("Cardlines.8")}</h1>
              </div>

              <Grid container rowSpacing={1}>
                <Grid xs={12} md={6} >
                  <div className="explore-card">
                    <div className="ico-text-inline">
                      <img src={mmx} alt="" />
                      <p className="rss-text">MMX</p>
                    </div>
                    <p className="card-des">{t("Cardlines.9")}</p>
                    <p className="small-text">
                      {t("Cardlines.11")} APR: {`${formatKeyAmount(processedData, "gmxAprTotal", 2, 2, true)}%`}
                    </p>
                    <div className="card-btns">
                      <a href="https://app.minmax.exchange/#/buy_mmx" className="by-btn" target="_blank" rel="noopener noreferrer">{t("Cardlines.12")}</a>
                      {/* <button className="by-btn">{t("Cardlines.12")}</button> */}
                      <a href="https://minmaxdex.gitbook.io/minmaxdex/tokenomics/mmx" className="read-btn" target="_blank" rel="noopener noreferrer">{t("Cardlines.13")}</a>
                      {/* <button className="read-btn">{t("Cardlines.13")}</button> */}
                    </div>
                  </div>
                </Grid>
                <Grid xs={12} md={6}>
                  <div className="explore-card">
                    <div className="ico-text-inline">
                      <img src={lmx} alt="" />
                      <p className="rss-text">MLP</p>
                    </div>
                    <p className="card-des">{t("Cardlines.10")}</p>
                    <p className="small-text">
                      {t("Cardlines.11")} APR: {`${formatKeyAmount(processedData, "glpAprTotal", 2, 2, true)}%`}
                    </p>
                    <div className="card-btns">
                      <a href="https://app.minmax.exchange/#/buy_mlp" className="by-btn" target="_blank" rel="noopener noreferrer">{t("Cardlines.12")}</a>
                      {/* <button className="by-btn">{t("Cardlines.12")}</button> */}
                      {/* <button className="read-btn">{t("Cardlines.13")}</button> */}
                      <a href="https://minmaxdex.gitbook.io/minmaxdex/tokenomics/mlp" className="read-btn" target="_blank" rel="noopener noreferrer">{t("Cardlines.13")}</a>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
        <Footer />
      </div>
    );
};

export default Home;