import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
// import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Container } from '@mui/system';
// import { Grid, ListItemButton } from '@mui/material';
import { Grid } from '@mui/material';
import logo from '../../asset/logo.png'
import closeIcon from '../../asset/icons8-close-50.png'
import langIcon from '../../asset/icons8-globe-24.png'
import './Header.css'
import english from '../../asset/flags/english.png'
import Spanish from '../../asset/flags/spin.png'
import jhapan from '../../asset/flags/jhapan.png'
import Korean from '../../asset/flags/korian.png'
import chaina from '../../asset/flags/china.png'
import { useTranslation } from "react-i18next";
// import Navigation from "react-router";
// import { useEffect } from 'react';

const drawerWidth = 300;

function Header(props) {
  const { t, i18n } = useTranslation();
  function clickLanguage(lang) {
    i18n.changeLanguage(lang); 
  }
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  const location = useLocation();
  const navigate = useNavigate();
  // const navigation = useNavigation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navItems = [
    t("Navlink.1"),
    t("Navlink.2"),
    t("Navlink.3"),
    t("Navlink.4"),
    t("Navlink.5"),
    // t("Navlink.6"),
  ];

// modal style//
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#14141c',
  border: '1px solid #aaa',
  boxShadow: 24,
  borderRadius:'10px',
  p: 4,
};

const [opens, setOpens] = React.useState(false);
const handleOpens = () => setOpens(true);
const handleCloses = () => setOpens(false);
// const handleClick = () => {
//   console.log("---shark");
//   // window.location.href = `https://app.minmax.exchange/#/referrals`
//   if (window !== undefined) {
//     window.location.open(`https://app.minmax.exchange/#/referrals`, '_blank')
//   }
// };


  const drawer = (
    
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'start' }} className="sideNave">
       {/* ======================================
       ============================================= hi!! mobile drawer menu is here/// */}
      <div className='drawer-icons'>
      <img src={logo} alt="" />
      <img style={{maxWidth:'25px', cursor:'pointer'}} src={closeIcon} alt="" />
      </div>
      <div className='divider'></div>
      <List>
        {/* {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'start' }} className="mobile-items">
              <ListItemText primary={item} />
              
            </ListItemButton>
          </ListItem>
        ))} */}
        <ListItem disablePadding>
              <a href="https://app.minmax.exchange/#/dashboard" className="mobile-items" target="_blank" rel="noopener noreferrer">
              {t("Navlink.1")}
              </a>          
        </ListItem>
        <ListItem disablePadding>
              <a href="https://app.minmax.exchange/#/earn" className="mobile-items" target="_blank" rel="noopener noreferrer">
              {t("Navlink.2")}
              </a>          
        </ListItem>
        <ListItem disablePadding>
              <a href="https://app.minmax.exchange/#/buy" className="mobile-items" target="_blank" rel="noopener noreferrer">
              {t("Navlink.3")}
              </a>          
        </ListItem>
        <ListItem disablePadding>
              <a href="https://app.minmax.exchange/#/referrals" className="mobile-items" target="_blank" rel="noopener noreferrer">
              {t("Navlink.4")}
              {/* <ListItemText primary={t("Navlink.4")} /> */}
              </a>          
        </ListItem>
        <ListItem disablePadding>
              <a href="https://app.minmax.exchange/#/ecosystem" className="mobile-items" target="_blank" rel="noopener noreferrer">
              {t("Navlink.5")}
              {/* <ListItemText primary={t("Navlink.5")} /> */}
              </a>          
        </ListItem>

        <ListItem disablePadding>
              <a href="https://minmaxdex.gitbook.io/minmaxdex/" className="mobile-items" target="_blank" rel="noopener noreferrer">
              {t("Navlink.6")}
              {/* <ListItemText primary={t("Navlink.5")} /> */}
              </a>          
        </ListItem>
        
        
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box sx={{ display: "flex" }} className="heading-section">
      <AppBar component="nav" className="navBar">
        <Container style={{ maxWidth: "1400px" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <img
              style={{ maxWidth: "60px" }}
              src={logo}
              alt="logo"
              className="mobile-logo"
            />
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: { xs: 0, sm: 1 },
                display: { xs: "block", sm: "block" },
              }}
            >
              {/* ======================================
        ============================================= hi!! desktop menu is here/// */}
              <Box
                sx={{ display: { xs: "none", sm: "none", md: "block" } }}
                className="desktop-items"
              >
                <a href="https://app.minmax.exchange/#/dashboard" className="desktop-items-btn" target="_blank" rel="noopener noreferrer">
                {t("Navlink.1")}
                </a>
                <a href="https://app.minmax.exchange/#/earn" className="desktop-items-btn" target="_blank" rel="noopener noreferrer">
                {t("Navlink.2")}
                </a>
                <a href="https://app.minmax.exchange/#/buy" className="desktop-items-btn" target="_blank" rel="noopener noreferrer">
                {t("Navlink.3")}
                </a>
                <a href="https://app.minmax.exchange/#/referrals" className="desktop-items-btn" target="_blank" rel="noopener noreferrer">
                {t("Navlink.4")}
                </a>
                <a href="https://app.minmax.exchange/#/ecosystem" className="desktop-items-btn" target="_blank" rel="noopener noreferrer">
                {t("Navlink.5")}
                </a>
                <a href="https://minmaxdex.gitbook.io/minmaxdex/" className="desktop-items-btn" target="_blank" rel="noopener noreferrer">
                {t("Navlink.6")}
                </a>
              </Box>
            </Typography>
            <Box
              sx={{ display: { xs: "block", sm: "block" } }}
              className="fixed-items"
            >
              {/* <button className="trade-btn">{t("Navlink.7")}</button> */}
              <a href="https://app.minmax.exchange/#/trade" className="trade-btn" target="_blank" rel="noopener noreferrer">
              {t("Navlink.7")}
                </a>
              <button className="connect-btn" style={{ marginLeft: "7px" }}>
                <div className="connect-btns" onClick={handleOpens}>
                  <img src={langIcon} alt="doot" />
                </div>
              </button>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={opens}
                onClose={handleCloses}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={opens}>
                  <Box sx={style}>
                    <p className="modal-text">{t("Languages.6")}</p>
                    <img
                      onClick={handleCloses}
                      src={closeIcon}
                      alt=""
                      className="moodal-close-btn"
                    />
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid item xs={6}>
                        <button
                          className="language-btn"
                          onClick={() => clickLanguage("en")}
                        >
                          <img src={english} alt="" />
                          <span>{t("Languages.1")}</span>
                        </button>
                      </Grid>
                      <Grid item xs={6}>
                        <button
                          className="language-btn"
                          onClick={() => clickLanguage("es")}
                        >
                          <img src={Spanish} alt="" />
                          <span>{t("Languages.2")}</span>
                        </button>
                      </Grid>
                      <Grid item xs={6}>
                        <button
                          className="language-btn"
                          onClick={() => clickLanguage("ja")}
                        >
                          <img src={jhapan} alt="" />
                          <span>{t("Languages.5")}</span>
                        </button>
                      </Grid>
                      <Grid item xs={6}>
                        <button
                          className="language-btn"
                          onClick={() => clickLanguage("ko")}
                        >
                          <img src={Korean} alt="" />
                          <span>{t("Languages.4")}</span>
                        </button>
                      </Grid>
                      <Grid item xs={6}>
                        <button
                          className="language-btn"
                          onClick={() => clickLanguage("cn")}
                        >
                          <img src={chaina} alt="" />
                          <span>{t("Languages.3")}</span>
                        </button>
                      </Grid>
                    </Grid>
                  </Box>
                </Fade>
              </Modal>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          className="sideNave"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;